import { canUseDOM } from '../helpers/can-use-dom'
import Layout from '../components/layout'
import { navigate } from '@reach/router'
import SEO from '../components/seo'
// noinspection ES6CheckImport
import {
    Configure,
    Hits,
    Index,
    InstantSearch,
    PoweredBy,
} from 'react-instantsearch-dom'
import { createURL, CustomStateResults, DEBOUNCE_TIME, DebouncedSearchBox, Hit, searchClient, searchStateToUrl, urlToSearchState } from '../helpers/algolia'
import React, { useState } from 'react'

const Search = () => {
    const ssrLocation = {
        search: null,
    }

    const [searchState, setSearchState] = useState(urlToSearchState(canUseDOM === false ? ssrLocation : location))
    const [debouncedSetState, setDebouncedSetState] = useState(null)

    const onSearchStateChange = (updatedSearchState) => {
        // noinspection JSCheckFunctionSignatures
        clearTimeout(debouncedSetState)

        setDebouncedSetState(
            setTimeout(() => {
                navigate(searchStateToUrl(canUseDOM === false ? ssrLocation : location, updatedSearchState)).then()
            }, DEBOUNCE_TIME),
        )

        setSearchState(updatedSearchState)
    }

    // TODO
    return (
        <Layout>
            <SEO
                title="Search"
            />
            <InstantSearch
                indexName="resources"
                searchClient={searchClient}
                searchState={searchState}
                onSearchStateChange={onSearchStateChange}
                createURL={createURL}
            >
                <div className="site-search">
                    {/* TODO: Add clear search button */}
                    <DebouncedSearchBox delay={500} />
                </div>
                <div className="search-results">
                    <h2 className="search-results-title">Page results...</h2>
                    <div className="content-set-block single-row">
                        <Index indexName="pages">
                            <Configure hitsPerPage={8} />
                            <Hits hitComponent={Hit} />
                            <CustomStateResults />
                        </Index>
                    </div>
                    <h2 className="search-results-title">News results...</h2>
                    <div className="content-set-block single-row">
                        <Index indexName="resources">
                            <Configure hitsPerPage={8} />
                            <Hits hitComponent={Hit} />
                            <CustomStateResults />
                        </Index>
                    </div>
                    <PoweredBy/>
                </div>
            </InstantSearch>
        </Layout>
    )
}

export default Search
